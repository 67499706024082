import React from 'react';
import Layout from './Layout'
import "./App.css";

const animations = [
  "rotation",
  "movement",
  "scale"
]

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      id: 1,
      frame: 0,
      animationId: 0,
      layouts: [
        { 
          id: 1,
          easing: "easeInQuad",
          inDelay: 0,
          outDelay: 0,
          time: 5000
        }
      ]
    }

    this.addLayout = this.addLayout.bind(this)
    this.restart = this.restart.bind(this)
    this.changeAnimation = this.changeAnimation.bind(this)
  }

  addLayout() {
    const layouts = this.state.layouts
    const lastLayout = layouts[layouts.length - 1]

    this.setState(prevState => ({
      id: (prevState.id + 1),
      frame: this.state.frame + 1,
      layouts: [...prevState.layouts, {
        id: (prevState.id + 1),
        easing: lastLayout.easing,
        inDelay: lastLayout.inDelay,
        outDelay: lastLayout.outDelay,
        time: lastLayout.time
      }]
    }))
  }

  restart() {
    this.setState({ frame: this.state.frame + 1 })
  }

  changeAnimation() {
    const aid = (this.state.animationId + 1) % animations.length
    
    this.setState({ 
      animationId: aid
    })
  }

  update(layout, key, value) {
    const index = this.state.layouts.findIndex(l => l.id === layout.id)
    const layouts = [...this.state.layouts]
    layouts[index][key] = value
    this.setState({ layouts })
  }

  render() {
    const layouts = this.state.layouts.map((layout, index) => {
      return <Layout 
        key={index} 
        animation={animations[this.state.animationId]} 
        frame={this.state.frame} 
        parent={this} 
        layout={layout} 
        {...layout} 
      />
    })

    return (
      <div className="App">
        <div className="layouts">
          {layouts}
        </div>
        <div className="actions">
          <a href="#" className="add" onClick={this.addLayout}>
            Add animation
          </a>
          <a href="#" className="restart" onClick={this.restart}>
            Restart all
          </a>
          <a href="#" className="restart" onClick={this.changeAnimation}>
            Change Animation
          </a>
        </div>        
      </div>
    );
  }
}

export default App;
