import React from 'react';
import easings from './easings';
import RotationAnimation from './animations/Rotation';
import MovementAnimation from './animations/Movement';
import ScaleAnimation from './animations/Scale';

import "./Layout.css";

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      frame: 0
    }

    this.animate = this.animate.bind(this)
  }

  animate() {
    this.setState(prevState => ({ 
      frame: (prevState.frame + (1000 / 60))
    }))
    
    requestAnimationFrame(this.animate)
  }

  componentDidMount() {
    this.animate()
  }

  componentDidUpdate(p) {
    const animationTime = this.animationTime()

    if (
      (p.time + p.inDelay + p.outDelay !== animationTime) ||
      (this.props.easing !== p.easing) ||
      (this.props.frame !== p.frame)
    ) {
      this.setState({ frame: 0 })
    }

    if (this.state.frame / animationTime > 1) {
      this.setState({ frame: 0 })
    }
  }

  animationTime() {
    const { time, inDelay, outDelay } = this.props
    return time + inDelay + outDelay
  }

  animationPercentage() {
    return this.state.frame / this.animationTime()
  }

  movingPercentage() {
    const frame = this.state.frame - this.props.inDelay
    const percentage = frame / this.props.time
    return Math.max(0, Math.min(1, percentage))
  }

  animationY() {
    const animationPercentage = this.movingPercentage()
    const easingType = this.props.easing
    return easings[easingType](animationPercentage)
  }

  pipY(i) {
    const { time, inDelay, outDelay } = this.props
    const animationTime = this.animationTime()

    const pcMoving = time / animationTime
    const pcStart = inDelay / animationTime

    const realI = Math.max(0, Math.min(1, ((i - pcStart) / pcMoving)))

    const easingType = this.props.easing
    return easings[easingType](realI)
  }

  inWidth() {
    return this.props.inDelay / this.animationTime()
  }

  outWidth() {
    return this.props.outDelay / this.animationTime()
  }

  animation() {
    const a = this.props.animation
    console.log(a)
    if (a === "rotation") {
      return <RotationAnimation percentage={this.animationY()} />
    } else if (a === "scale") {
      return <ScaleAnimation percentage={this.animationY()} />
    } else {
      return <MovementAnimation percentage={this.animationY()} />
    }
  }

  svg() {
    const pipAmount = 100
    const path = "M " + [...Array(pipAmount).keys()].map(i => {
      return `${1 + (i * 300 / pipAmount)} ${151 - 150 * this.pipY(i / pipAmount)}`
    }).join(" L ") + " L 301 1"

    return (
      <svg preserveAspectRatio="none" viewBox="0 0 302 152">
        <path d={path}></path>
      </svg>
    )
  }

  render() {
    const animationTime = this.animationTime()
    const animationPercentage = this.animationPercentage()
    const easingOptions = Object.keys(easings)

    return (
      <div className="layout">
        <div>
          <div className="props">
            <div>
              <label>Easing:</label>
              <select onChange={event => { this.props.parent.update(this.props.layout, "easing", event.target.value) }} value={this.props.easing}>
                {easingOptions.map((o, i) => <option key={i} value={o}>{o}</option>)}
              </select>
            </div>
            <div>
              <label>Timing: <span>{this.props.time}ms</span></label>
              <input type="range" min="1000" max="10000" step="250" value={this.props.time} onChange={event => { this.props.parent.update(this.props.layout, "time", parseInt(event.target.value)) }} />
            </div>
            <div>
              <label>Delay in: <span>{this.props.inDelay}ms</span></label>
              <input type="range" min="0" max="5000" step="250" value={this.props.inDelay} onChange={event => { this.props.parent.update(this.props.layout, "inDelay", parseInt(event.target.value)) }} />
            </div>
            <div>
              <label>Delay out: <span>{this.props.outDelay}ms</span></label>
              <input type="range" min="0" max="5000" step="250" value={this.props.outDelay} onChange={event => { this.props.parent.update(this.props.layout, "outDelay", parseInt(event.target.value)) }} />
            </div>
          </div>
          <div className="graph">
            <p>{animationTime}ms</p>

            {this.svg()}

            <div className="in-area" style={{width: (100 * this.inWidth()) + "%"}}></div>
            <div className="out-area" style={{width: (100 * this.outWidth()) + "%"}}></div>

            <div className="progress" style={{left: (100 * animationPercentage) + "%", bottom: (100 * this.animationY()) + "%" }}></div>
            <div className="progress" style={{left: (100 * animationPercentage) + "%" }}>
              <span>{Math.round(100 * animationPercentage)}%</span>
            </div>
          </div>
          <div className="animation">
            {this.animation()}
          </div>        
        </div>
      </div>
    );
  }
}

export default Layout;
