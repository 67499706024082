import React from 'react';

class MovementAnimation extends React.Component {
  render() {
    return (
      <div className="square small" style={{transform: `scale(${1 + this.props.percentage})`}}></div>
    )
  }
}

export default MovementAnimation;
