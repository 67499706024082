import React from 'react';

class MovementAnimation extends React.Component {
  render() {
    return (
      <div className="square small" style={{transform: `translate(${150 * this.props.percentage - 75}px, 0)`}}></div>
    )
  }
}

export default MovementAnimation;
