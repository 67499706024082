import React from 'react';

class RotationAnimation extends React.Component {
  render() {
    return (
      <div className="square" style={{transform: `rotate(${90 * this.props.percentage}deg`}}></div>
    )
  }
}

export default RotationAnimation;
